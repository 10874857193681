<template>
  <div class="my_main">
    <div class="my_avatar">
      <div class="avatar" v-if="this.$store.state.user.nick === ''">{{this.$store.state.user.name.length>3?this.$store.state.user.name.substring(0,3):this.$store.state.user.name}}</div>
      <img v-else :src="this.$store.state.user.nick" alt="">
      <span>{{ this.$store.state.user.name }}</span>
    </div>
    <div class="my_Telephone">
      <i></i>
      <span>{{ this.$store.state.user.phone.slice(0,3) +'****'+ this.$store.state.user.phone.slice(-4)}}</span>
    </div>
    <div class="my_setting" @click="goSet()">
      <div class="my_setting_left">
        <i></i>
        <span>设置</span>
      </div>
      <van-icon name="arrow" color="#000000"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "my",
  data() {
    return {
      userInfo: {},
      phone: ''
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    goSet() {
      this.$router.push({name: 'setting'})
    }
  }
}
</script>

<style scoped lang="scss">
.my_main {
  .my_avatar {
    width: 100%;
    height: 77px;
    box-sizing: border-box;
    padding: 0 24px;
    background: #FFFFFF;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    .avatar{
      width: 49px;
      height: 49px;
      border-radius: 49px;
      margin-right: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F8F8F8;
      border: 1px solid #EEEEEE;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999;
    }
    img {
      width: 49px;
      height: 49px;
      border-radius: 49px;
      margin-right: 14px;
    }

    span {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D1D1D;
      line-height: 22px;
    }
  }

  .my_Telephone {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    padding: 0 24px;
    background: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #F2F3F5;
    i {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("../../assets/images/phone_icon.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      margin-right: 12px;
    }
    span{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1D1D1D;
      line-height: 20px;
    }
  }
  .my_setting{
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    padding: 0 24px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .my_setting_left{
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url("../../assets/images/setting_icon.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        margin-right: 12px;
      }
      span{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D1D1D;
        line-height: 20px;
      }
    }
  }
}
</style>
